.page-navigation-container {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.page-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.centered-text {
  position: absolute;
  top: 15.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  font-weight: bold;
  color: white;
}

.tabs-container {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.tab-button {
  padding: 10px 15px;
  background-color: silver;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
}

.tab-button.active {
  background-color: #192152;
  color: white;
}

.bhi {
  background-image: url("../Assets/6.png");
}

.kis {
  margin-top: 75px;
}

.page-image {
  width: 800px;
  max-height: auto;
  border-radius: 8px;
  /* You can add other default styles here */
}

.resource-table-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.resource-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.resource-table th,
.resource-table td {
  border: 1px solid #ddd;
  padding: 10px;
  vertical-align: middle;
}

.resource-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.resource-link {
  color: #007bff;
  text-decoration: none;
}

.resource-link:hover {
  text-decoration: underline;
}

.resource-video,
.resource-audio {
  width: 100%;
  height: auto;
  max-width: 300px;
  border-radius: 5px;
}

@media (max-width: 1200px) {
  .page-image {
    width: 400px;
  }
}

@media (max-width: 992px) {
  .page-image {
    width: 250px;
  }
}

@media (max-width: 768px) {
  .page-image {
    width: 200px;
  }
}

@media (max-width: 576px) {
  .page-image {
    width: 100%;
    border-radius: 5px;
  }
}

.nav-arrow {
  cursor: pointer;
  font-size: 3rem; /* Increase size for better visibility */
  padding: 10px;
  color: #333; /* Arrow color */
  transition: color 0.3s; /* Color transition */
  z-index: 10; /* Ensure arrows are above content */
}

@media (max-width: 1200px) {
  .nav-arrow {
    width: 400px;
  }
}

@media (max-width: 992px) {
  .nav-arrow {
    width: 350px;
  }
}

@media (max-width: 768px) {
  .nav-arrow {
    width: 300px;
  }
}

@media (max-width: 576px) {
  .nav-arrow {
    width: 100%;
    border-radius: 5px;
  }
}

.nav-arrow:hover {
  color: #007bff; /* Change color on hover */
}

.no-pages-found {
  text-align: center;
  font-size: 1.5rem;
  color: #555;
}

.resources-container {
  padding: 20px;
  background-color: #f8f9fa; /* Light background for contrast */
}

.resources-container h3 {
  margin-bottom: 10px;
}

.resources-container ul {
  list-style-type: none; /* Remove default list styles */
  padding: 0; /* Remove padding */
}

.resources-container li {
  padding: 5px 0; /* Spacing between items */
}

.zampa {
  height: 100px;
  width: 200px;
}
