@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Wix+Madefor+Text:ital,wght@0,400..800;1,400..800&display=swap");

body {
  font-family: "Nunito" !important;
}

.custom-input {
  width: 300px;
}

.rem {
  height: 600px;
}

.zoho {
  margin-top: 100px;
}

.comb {
  font-size: small;
}

.input-container {
  position: relative;
  margin: 20px 0;
  width: 100%;
}

/* Styling for input field */
.custom-input {
  width: 100%;
  padding: 10px 0;
  border: none;
  border-bottom: 2px solid #ccc;
  font-size: 16px;
  background: none;
  outline: none;
}

.custom-select {
  width: 100%;
  padding: 10px 0;
  border: none;
  border-bottom: 2px solid #ccc;
  font-size: 16px;
  background: none;
  outline: none;
  appearance: none;
}

/* The label starts as a placeholder */
.input-label {
  position: absolute;
  left: 0;
  top: 10px;
  font-size: 16px;
  color: #aaa;
  transition: 0.3s ease all;
  pointer-events: none;
}

/* When the input is focused or has value, the label moves up */
.custom-input:focus ~ .input-label,
.custom-input:not(:placeholder-shown) ~ .input-label {
  top: -20px;
  font-size: 12px;
  color: #333;
}

/* Focus effect on the input field */
.custom-input:focus {
  border-bottom: 2px solid #333;
}

.custom-select:focus {
  border-bottom: 2px solid #333;
  outline: none;
}

.custom-select option {
  color: black;
}

.custom-select:focus ~ .input-label,
.custom-select:not([value=""]) ~ .input-label {
  top: -20px;
  font-size: 12px;
  color: #333;
}

/* Focus effect on the select */
.custom-select:focus {
  border-bottom: 2px solid #333;
}
