/* src/pages/Styles/Home.css */

.banner-container {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 55px; /* Adjust this value to match the height of your fixed header */
  position: relative;
  width: 100%;
}

.banner-container img {
  width: 100%;
  height: 550px;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.col {
  padding-left: 0;
  padding-right: 0;
}

/* src/Components/Styles/Home.css */

.banner-container {
  position: relative;
}

.banner-text {
  font-size: 2rem;
  font-weight: bold;
  font-size: 50px;
  color: white;
}

.banner-container {
  position: relative;
}

.banner {
  width: 100%;
  height: auto;
  opacity: 1;
  filter: brightness(1);
  transition: opacity 0.5s ease-in-out;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

.banner-text-container {
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.banner-text {
  font-size: 4rem;
  font-weight: bold;
}

.banner-button {
  font-size: 1.2rem;
  background-color: white;
  color: black;
  outline: none;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .banner-text {
    font-size: 1.5rem;
  }

  .banner-button {
    font-size: 1rem;
  }
}
