.xsa {
    background-image: url('../Assets/bg-2.jpg'); 
    background-size: cover;
    background-repeat: no-repeat;
}


.rn1{
    color: orangered;
    letter-spacing: 3px;
    font-weight: bold;

  }

