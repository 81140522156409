.sidebar {
    position: fixed;
    top: 0;
    right: -100%;
    width: 300px;
    height: 100%;
    background: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    transition: all 0.3s;
    z-index: 1000;
}

.sidebar.open {
    right: 0;
}

.close-btn {
    position: absolute;
    top: 20px;
    left: 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.sidebar-content {
    padding: 20px;
}

.sidebar-content .logo img {
    width: 200px;
    margin-bottom: 20px;
    margin-top: 80px;
    margin-left: 20px;
    border-radius: 5px;
}

.sidebar-content p {
    margin-bottom: 20px;
}

.sidebar-map{
    width: 100;
    height: 100; 
}

.sidebar-images img {
    width: 100%;
    margin-bottom: 10px;
}

.sidebar-location {
    margin-bottom: 20px;
}

.sidebar-social a {
    font-size: 20px;
    margin-right: 10px;
    color: #333;
}

