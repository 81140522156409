

.profile-cont {
  border-radius: 8px;
  padding: 20px;
}

.profile-pic-img {
  border: 2px solid;
}

.vertical-buttons button {
  margin-bottom: 10px;
}

.diskdil{
  background-image: url("../Assets/banner-3.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 120px;
  background-position: center;
}

.card-title {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.card {
  transition: transform 0.2s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.banner-container2{
  height: 100px;
  margin-bottom: 80px;
}




