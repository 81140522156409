.zo {
    background-image: url('../Assets/banner-2.webp'); 
    background-size: cover;
    background-repeat: no-repeat;
}


.zgo {
    background-image: url('../Assets/logo-2.jpeg'); 
    background-size: cover;
    background-repeat: no-repeat;
    background-size: 80%;

}


.about-title{
    letter-spacing: 3px;
}

.about-content{
    line-height: 30px;
}


/* src/Components/Styles/Aboutus.css */

.zo {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.text-container {
    max-width: 600px;
    text-align: left;
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 600px;
}

.image-container img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

/* Responsive design for smaller screens */
@media (max-width: 992px) {
    .m-5.d-flex {
        flex-direction: column-reverse;
    }
    
    .text-container, .image-container {
        max-width: 100%;
    }
    
    .image-container {
        margin-bottom: 20px;
    }
    
    .text-container {
        text-align: center;
    }
}
