.containerx {
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
}

.zala {
  background-image: url("../Assets/bg-7-full.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  .book-item {
    width: 45%;
  }
}

@media (max-width: 576px) {
  .book-item {
    width: 100%;
  }
}

.book-item {
  box-shadow: 0px 0px 4px lightgrey;
  border: 1px solid lightgrey;
  padding: 5px;
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.book-item:hover {
  transform: translateY(-10px);
}

.book-cover {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-grow: 1;
}

.book-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.book-link {
  text-decoration: none;
  color: inherit;
}

.nfr-dim {
  height: 150px;
  width: 150px;
}

.book-link:hover {
  text-decoration: underline;
}

.h5 {
  margin-top: 10px;
}
